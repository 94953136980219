<!--
 * @Author: Neko
 * @Date: 2021-01-11 15:56:18
 * @LastEditTime: 2021-01-16 16:45:19
 * @LastEditors: Neko
-->
<template>
  <div class="collection__container">
    <div class="collection__wrap">
      <Breadcrumbs />

      <GoodsList class="goods-list" :data="goodsList" :is-show-crash-can="true" @delete="onDeleteCollection" />

      <Pagination :data="pageData" @current-change="onCurrentChange" />
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from '@vue/composition-api'
import { useRequest } from '@/utils/request'
import { MessageBox } from 'element-ui'

import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue'
import GoodsList from '@/components/Common/GoodsList.vue'
import Pagination from '@/components/Common/Pagination.vue'
import { getDomainPrefix } from '@/utils'

export default {
  name: 'CollectionList',
  components: {
    Breadcrumbs,
    GoodsList,
    Pagination
  },

  setup(_, { root }) {
    const $t = root.$jst
    const data = reactive({
      goodsList: [],

      pageData: {
        pageSize: process.env.VUE_APP_PAGE_SIZE,
        pageNo: 1,
        total: 0
      },

      deleteGoodsId: []
    })

    const { data: getGoodsCollectionResult, fetch } = useRequest('goods/getGoodsCollectionList', {
      data: {
        pageSize: data.pageData.pageSize,
        pageNo: data.pageData.pageNo,
        domainPrefix: getDomainPrefix
      }
    })

    watch(() => getGoodsCollectionResult.value, res => {
      data.goodsList = res.records
      data.total = res.total
    })

    const onCurrentChange = page => {
      data.pageData.pageNo = page
      fetch()
    }

    const { fetch: handleDeleteGoodsCollectionFetch } = useRequest('goods/handleDeleteGoodsCollectionAction', {
      data: computed(() => ({
        goodsCollectIdList: [data.deleteGoodsId]
      })),

      immediate: false,

      onSuccess: () => {
        fetch()
      }
    })

    const onDeleteCollection = goods => {
      MessageBox.confirm($t('account.whetherDelColl'), $t('account.tips'))
        .then(() => {
          data.deleteGoodsId = goods.id

          handleDeleteGoodsCollectionFetch()
        })
        .catch(() => {})
    }

    return {
      ...toRefs(data),
      onCurrentChange,

      onDeleteCollection
    }
  }
}
</script>

<style lang="scss" scoped>
.collection__container {

}

.collection__wrap {
  width: 1200px;
  padding: 24px 0 90px;
  margin: 0 auto;
}

.goods-list {
  margin-top: 22px;
}
</style>
