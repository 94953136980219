<!--
 * @Author: Neko
 * @Date: 2021-01-06 13:15:24
 * @LastEditTime: 2021-03-23 15:20:18
 * @Description: 单个商品组件
 * @LastEditors: Neko
-->
<template>
  <div class="goods__container">
    <a v-if="isShowCrashCan" class="crash-can" href="javascript:void(0)" @click="onClickDeleteBtn">
      <i class="el-icon-delete-solid" />
    </a>

    <router-link :to="{ name: 'GoodsDetail', query: { id: data.goodsId } }" class="link" target="_blank">
      <div class="goods__image">
        <el-image class="img" :src="data.goodsPicUrl" lazy />
      </div>

      <p class="goods__name">
        {{ data.commonName || data.goodsName }}
      </p>

      <div class="goods__price">
        {{ data.buyPrice || data.buyPriceDesc }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Goods',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isShowCrashCan: {
      type: Boolean,
      default: false
    }
  },

  emits: ['delete'],

  setup(props, { root, emit }) {
    const onClickDeleteBtn = _ => {
      emit('delete', props.data)
    }

    return {
      onClickDeleteBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.goods__container {
  position: relative;
  width: 280px;
  // min-height: 392px;
  // border: 1px solid #D2D2D2;
  border-radius: 8px;
  // padding: 18px 15px 16px 15px;
  margin-bottom: 20px;
  box-shadow: 0px 6px 12px 0px #E8E8EA;
  background: #fff;
  transition: .3s;

  // &:hover {
  //   border-color: #0049ac;

  //   .goods__image .img {
  //     transform: scale(1.5);
  //   }
  // }

  // &::after, &::before {
  //   content: '';
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   border-radius: 16px;
  //   transition: .3s;
  //   pointer-events: none;
  // }

  // &::before {
  //   left: -1px;
  //   top: -1px;
  //   border-top: 1px solid #0049ac;
  //   border-left: 1px solid #0049ac;
  // }

  // &::after {
  //   right: -1px;
  //   bottom: -1px;
  //   border-bottom: 1px solid #0049ac;
  //   border-right: 1px solid #0049ac;
  // }

  // &:hover {
  //   &::after, &::before {
  //     width: 100%;
  //     height: 380px;
  //   }
  // }

  .crash-can {
    position: absolute;
    top: 18px;
    right: 17px;
    background-color: rgba(255, 255, 255, .4);
    z-index: 2;
  }

  .link {
    display: block;
  }

  .goods__image {
    width: 280px;
    height: 280px;
    padding: 18px 15px 16px 15px;
    border-bottom: 1px solid #EEEEEE;
    // margin-bottom: 13px;
    margin: 0 auto;
    overflow: hidden;

    .img {
      width: 100%;
      height: 100%;
      transform: scale(1);
      transition: .3s;
    }
  }

  .goods__name {
    @include line-clamp(2);
    height: 60px;
    padding: 19px 9px 14px 19px;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
    color: #666666;
  }

  .goods__price {
    padding: 0 0 20px 21px;
    font-size: 16px;
    // font-weight: bold;
    color: #FF2900;

    // @include currency;
  }
}
</style>
