<!--
 * @Author: Neko
 * @Date: 2021-01-11 09:24:30
 * @LastEditTime: 2021-03-18 15:29:43
 * @LastEditors: Neko
-->
<template>
  <ul class="breadcrumbs">
    <li class="icon-box">
      <i class="icon el-icon-document-copy" />
    </li>
    <li v-for="(item, index) in data" :key="item.id" class="item">
      <a class="link" href="javascript:void(0)" @click="onClickItemBtn(index)">
        {{ item[nameLabel] }}

        <i v-if="index !== data.length - 1">/</i>
      </a>
    </li>
  </ul>
</template>

<script>
import { toRefs } from '@vue/composition-api'

export default {
  name: 'Breadcrumbs',

  emits: ['click'],

  props: {
    data: {
      type: Array,
      default: () => ([])
    },

    nameLabel: {
      type: String,
      default: ''
    },

    valueLabel: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    const propsRef = toRefs(props)

    const onClickItemBtn = index => {
      const selected = propsRef.data.value.slice(1, index + 1)
      emit('click', selected)
    }

    return {
      onClickItemBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  margin: 4px 0 12px;

  .icon-box {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 8px;

    .icon {
      font-size: 14px;
      color: #E2E2E2;
    }
  }

  .item {
    display: inline-block;
    font-size: 14px;
    .link {
      color: #BBBBBB;
    }

    &:last-child {
      .link {
        color: #0049AC;
      }

    }

    .icon {
      display: inline-block;
      margin: 0 11px 0 12px;
      transform: scale(.8);
    }
  }
}
</style>
