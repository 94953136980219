<!--
 * @Author: Neko
 * @Date: 2021-01-06 13:13:11
 * @LastEditTime: 2021-03-26 11:56:21
 * @Description: 商品列表组件
 * @LastEditors: Neko
-->
<template>
  <div class="goods-list">
    <div v-if="data.length" class="goods-list__container" :class="'type-' + type">
      <Goods
        v-for="item in data"
        :key="item.id"
        :data="item"
        :is-show-crash-can="isShowCrashCan"
        @delete="onDeleteGoods"
      />
    </div>

    <Empty v-else />
  </div>
</template>

<script>
import Goods from './Goods.vue'
import Empty from './Empty.vue'

export default {
  name: 'GoodsList',

  components: {
    Goods,
    Empty
  },

  emits: ['delete'],

  props: {
    data: {
      type: Array,
      default: () => ([])
    },

    type: {
      type: [Number, String],
      default: 4
    },

    isShowCrashCan: {
      type: Boolean,
      default: false
    }
  },

  setup(_, { emit }) {
    const onDeleteGoods = goods => {
      emit('delete', goods)
    }

    return {
      onDeleteGoods
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-list__container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto 20px;
  @include clearfix;

  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;

  &.type-3 {
    & > * {
      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }
    }
  }

  &.type-4 {
    & > * {
      &:nth-of-type(4n + 4) {
        margin-right: 0;
      }
    }
  }

  & > * {
    // float: left;
    margin-right: 26px;
    margin-bottom: 10px;
  }
}
</style>
