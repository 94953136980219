<!--
 * @Author: Neko
 * @Date: 2021-01-18 09:46:22
 * @LastEditTime: 2021-03-16 16:04:13
 * @LastEditors: Neko
-->
<template>
  <div class="empty">
    <!-- 暂无数据 -->
    <div class="empty__container">
      <div class="img">
        <img src="@/assets/images/empty.png">
      </div>

      <p class="description">{{ $t('common.nullData') }}</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.empty {
  height: 505px;

  .empty__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 140px 0 163px;

    .img {
      position: relative;
      width: 160px;
      height: 160px;
      margin-bottom: 30px;
      overflow: hidden;
      border-radius: 50%;
      text-align: center;
      background: #fff;

      img {
        position: absolute;
        width: 75px;
        height: 75px;
        top: 42px;
        left: 42px;
      }
    }

    .description {
      font-size: 14px;
      letter-spacing: 2px;
      color: #AAAAAA;
    }
  }
}
</style>
